/* Login.css */
.login-page {
  display: flex;
  height: 100vh;
  width: 100vw;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

.login-page .login-image {
  flex: 1;
}

.login-page .login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-page .login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
  padding: 40px;
}

.login-page .login-form h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

.login-page .form-group {
  margin-bottom: 15px;
  width: 100%;
}

.login-page .form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
}

.login-page .form-group input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-page button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.login-page button:hover {
  background-color: #0056b3;
}
