.report-container {
    display: flex;
    flex-direction: column;
  }
  
  .report-table-container {
    margin-top: 20px;
  }
  
  @media print {
    body {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  
    .report-container {
      margin: 0;
      padding: 0;
    }
  
    .report-table-container {
      border: none;
    }
  
    .MuiDataGrid-root {
      border: none;
      font-size: 12px;
    }
  }
  