.status-card {
  margin: 10px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.horizontal-status {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.status-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: gray;
  position: relative;
}

.status-circle.completed {
  background-color: #4caf50;
}

.status-circle.in-progress {
  background-color: #ff9800;
}

.status-circle.pending {
  background-color: #7b7b7b;
}

.status-connector {
  flex-grow: 1;
  height: 4px; /* Thickness of the connecting line */
  background-color: gray;
  position: relative;
  top: -8px; /* Aligns the line with the center of the dots */
}

.status-connector.completed {
  background-color: #4caf50;
}

.status-connector.in-progress {
  background-color: #ff9800;
}

.status-connector.pending {
  background-color: #7b7b7b;
}
