.MuiDataGrid-menuIcon svg {
    color: #fff !important;
}

.MuiDataGrid-iconButtonContainer.css-ltf0zy-MuiDataGrid-iconButtonContainer svg {
    color: white !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent svg {
    color: #fff !important;
}